<template>
    <div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/title1.jpg" width="80%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/title2.jpg" width="80%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/title3.jpg" width="80%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/title4.jpg" width="80%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts1.jpg" width="60%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts2.jpg" width="60%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts3.jpg" width="60%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts4.jpg" width="60%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts5.jpg" width="60%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts6.jpg" width="60%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts7.jpg" width="60%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts8.jpg" width="60%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts9.jpg" width="60%" />
        </div>
    
    </div>
</template>
<script>
export default {
    name: 'FlowMeterDevice',
}
</script>
