<template>
    <div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/m_title1.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/m_title2.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/m_title3.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/m_title4.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts1.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts2.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts3.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts4.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts5.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts6.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts7.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts8.jpg" width="100%" />
        </div>
        <div style="width: 100%;margin-top: 20px;">
            <img src="https://qiniu.shunmingelectronic.com/guanwang/ts9.jpg" width="100%" />
        </div>
    
    </div>
</template>
<script>
export default {
    name: 'FlowMeterDevice',
}
</script>
