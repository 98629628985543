<template>
    <div id="app">
        <!-- 顶部导航栏 -->
        <div class="top-container">
            <div class="logo-container">
                <img src="../assets/logoc.png" alt="Company Logo" class="logo">
                <span class="company-name">舜铭电子信息科技有限公司</span>
            </div>
            <div class="menu-icon" @click="toggleMenu">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <!-- 菜单选项 -->
            <ul :class="{ 'nav-menu': true, 'active': menuActive }">

                <li @click="toggleDropdown(0, '', true)">
                    <a href="#">首页</a>
                    <ul v-if="activeDropdown === 0" class="dropdown-menu">
                    </ul>
                </li>

                <li @click="toggleDropdown(1, 'products')">
                    <a href="#">产品</a>
                    <ul v-if="activeDropdown === 1" class="dropdown-menu">
                        <li v-for="(subItem, subIndex) in productInfo" :key="subIndex"
                            @click="hanldClick('products', { categoryId: subItem.categoryId })">
                            <a href="#">{{ subItem.categoryEnName }}</a>
                        </li>
                    </ul>
                </li>
                <li @click="toggleDropdown(0, 'about', true)">
                    <a href="#">关于舜铭</a>
                    <ul v-if="activeDropdown === 0" class="dropdown-menu">
                    </ul>
                </li>
                <li @click="toggleDropdown(0, 'contact', true)">
                    <a href="#">联系我们</a>
                    <ul v-if="activeDropdown === 0" class="dropdown-menu">
                    </ul>
                </li>
                <li @click="toggleDropdown(0, 'contact', true)">
                    <a href="https://qiniu.shunmingelectronic.com/meter/apk/SMbluetooth.apk" target="_blank" rel="noopener noreferrer">
                        APP下载
                    </a>
                    <ul v-if="activeDropdown === 0" class="dropdown-menu">
                    </ul>
                </li>
                <!-- <li v-for="(item, index) in menuItems" :key="index"
                    @click="toggleDropdown(index, item.path, item.subMenu.length)">
                    <a href="#">{{ item.name }}</a>
                    <ul v-if="activeDropdown === index" class="dropdown-menu">
                        <li v-for="(subItem, subIndex) in item.subMenu" :key="subIndex" @click="hanldClick(item.path)">
                            <a href="#">{{ subItem }}</a>
                        </li>
                    </ul>
                </li> -->
            </ul>
        </div>

        <!-- 路由视图 -->
        <div class="router-view-container">
            <router-view></router-view>
        </div>
        <div style="background-color: #000000;">
            <div style="height: 100px; margin-top: 20px; ">
                <div><a style="color: aliceblue; margin-right: 30px; text-decoration: none;"
                        href="https://beian.miit.gov.cn" target="_blank">豫ICP备2024070819号-1</a>
                </div>
                <div> <a style="color: aliceblue; text-decoration: none;"
                        href="https://beian.mps.gov.cn/#/query/webSearch?code=41030502000977" rel="noreferrer"
                        target="_blank">豫公网安备41030502000977</a>
                    <img src="../assets/batb.png" style="width: 18px; height: 20px; margin-right: 10px;" />
                </div>

            </div>
            <div
                style="text-align: center; width: 100%;font-size: 18px; line-height: 60px; color: #ffffff; height: 60px;">
                {{ copyright }}
            </div>
        </div>
    </div>
</template>

<script>
import { jumpPage } from '../utils/public';

export default {
    name: 'App',
    data() {
        return {
            gsxx: `ShunMing International`,
            copyright: '版权所有：河南舜铭电子信息科技有限公司 ©2024 ',
            activeDropdown: null,
            menuActive: false,
            productInfo: [{ categoryEnName: '电磁流量计转换器', categoryId: 4 },{ categoryEnName: '智控仪表数据云平台', categoryId: 2 },{ categoryEnName: '慧光路灯智能云平台', categoryId: 1 },  { categoryEnName: '传统行业物联网改造升级', categoryId: 3 }]

        };
    },
    methods: {
        toggleMenu() {
            this.menuActive = !this.menuActive;
            this.activeDropdown = null; // 点击主菜单时收回子菜单
        },
        toggleDropdown(index, path, jump) {
            if (jump) {
                jumpPage(path);
                this.menuActive = false;
            }
            // 如果当前点击的菜单项已经展开，则收回子菜单
            if (this.activeDropdown === index) {
                this.activeDropdown = null;
            } else {
                this.activeDropdown = index; // 展开新的子菜单
            }
        },
        hanldClick(path, query) {
            jumpPage(path, query);
            this.menuActive = false;
        }
    },
    mounted() {
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.top-container {
    font-family: 'A';
    width: 100%;
    height: 50px;
    background-color: rgb(72 72 72);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 30px;
    margin-left: 20px;
}

.company-name {
    text-align: right;
    margin-right: 30px;
    line-height: 80px;
    color: transparent;
    font-size: 1rem;
    font-family: fangsong;
    font-weight: 900;
    background: linear-gradient(to right, rgb(109, 179, 242), rgb(193, 217, 255)) text;
}

.menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    cursor: pointer;
    margin-right: 1rem;
}

.menu-icon span {
    height: 3px;
    background-color: white;
    display: block;
    border-radius: 2px;
}

.nav-menu {
    list-style-type: none;
    display: none;
    /* 默认隐藏 */
    background-color: #000c;
    width: 50%;
    padding: 0;
    position: absolute;
    /* 使用绝对定位以确保下方的内容不受影响 */
    top: 35px;
    /* 让菜单在导航栏下方显示 */
    right: 0;
    z-index: 1001;
    /* 确保菜单在较高层次上 */

}

.nav-menu.active {
    display: block;
    border-bottom: #ffffff 2px solid;
}

.nav-menu li {
    text-align: left;
    position: relative;
}

.nav-menu li a {
    text-decoration: none;
    color: white;
    display: block;
    padding: 10px;
    border-bottom: #4e4e4e 1px solid;
}

.dropdown-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #00000000;
    width: 100%;
    position: relative;
    /* 子菜单相对于主菜单项排列 */
    top: auto;
    /* 防止子菜单覆盖主菜单 */
    z-index: 1002;
    /* 子菜单在主菜单之上 */
    display: none;
}

.nav-menu li .dropdown-menu {
    display: block;
    /* 由 toggleDropdown 控制显示 */
}

.dropdown-menu li {
    padding: 10px;
}

.dropdown-menu li a {
    color: white;
}

.router-view-container {
    position: relative;
    z-index: 1;
    padding-top: 45px;

    /* 确保内容不会被固定导航栏覆盖 */
}

.footer-container {
    background-color: #000000;
    padding-top: 10px;
    margin-top: 10px;
}

.footer-content {
    color: rgb(71, 71, 71);
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
}

.company-description {
    font-size: 25px;
    margin-left: 0px;
    color: #ffffff;
    text-align: center;
    width: 100%;
}

.footer-copyright {
    padding-top: 20px;
    color: white;
    text-align: center;
    width: 100%;
    font-size: 18px;
    height: 60px;
}
</style>
